import { css } from 'styled-components'
import { media } from './utils'

const type = {
    heading1: css`

    `,
    heading2: css`
 
    `,
    heading3: css`

    `,
    heading4: css`

    `,
    body: css`
        font-size: 22.1818px;
        line-height: 25px;

        ${media.phone`
            font-size: 18px;
            line-height: 21px;
        `}
    `,
}

export default type