import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';

import globalType from './type'
import globalColours from './colours';

export const maxWidth = 1180;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        color: black;
        font-family: 'Arial Narrow', Arial, sans-serif;
    }
    
    a {
        color: inherit;
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p, div {
        ${globalType.body};
        font-weight: 300;
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
    }

    p:first-child {
		margin-top: 0;
	}

    /* Next Wrapper */

    #gatsby-focus-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`

export const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
`


export const container = css`
    display: flex;
	flex-direction: column;
    /* width: ${maxWidth}px; */
    max-width: calc(100% - 248px);

    ${media.tablet`
        max-width: 100%;
    `}
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 24px; 
    padding-right: 24px;
    box-sizing: border-box;
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

// Re-export colours & type

export const colours = globalColours
export const type = globalType